.main-nav {
  background: $red;
  display: flex;
  flex-flow: row;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  @include mq('tablet') {
    overflow: visible;
  }
}

.main-nav__primary {
  margin: 0;
  padding: 0;
  overflow: hidden;
  @include mq('tablet') {
    overflow: visible;
  }

  li {
    display: block;
    list-style: none;
    position: relative;

    &.main-nav__has-drop {
      &:after {
        @include pseudo(block, absolute, $arrow);
        font-family: 'icomoon';
        right: rem(24);
        top: rem(24);
        font-size: rem(16);
        color: $red;
        transform: rotate(90deg);
        transition: transform 0.3s cubic-bezier(0, 0, 0.58, 1);
        @include mq('tablet') {
          display: none;
        }
      }
    }
    @include mq('tablet') {
      display: inline-block;
    }

    &.main-nav__primary--active {
      &:after {
        transform: rotate(-90deg);
      }

      > a {
        background: #fff;
        color: $red;
      }

      .sub-nav {
        opacity: 1;
        max-height: 1000px;
        @include mq('tablet') {
          z-index: 10;
        }
      }
    }

    a {
      padding: rem(24);
      display: block;
      text-decoration: none;
      font-family: $fjallaOne;
      color: $black;
      font-size: rem(21);
      transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
      @include mq('tablet') {
        color: $white;
      }

      &:hover {
        background: #fff;
        color: $red;
      }
    }
  }
}

.sub-nav {
  margin: 0;
  padding: 0;
  background: #fff;
  left: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
  @include mq('tablet') {
    min-width: 155%;
    position: absolute;
    z-index: -1;
    overflow: hidden;
  }

  li {
    width: 100%;

    a {
      color: $black;
      font-family: $barlow;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(48);
      padding: 0;
      display: block;
      padding: 0 rem(24);
      white-space: nowrap;
      transition: background 0.3s cubic-bezier(0, 0, 0.58, 1);

      &:hover {
        background: $lightGray;
      }
    }
  }
}

.fill {
  @include alpha-attribute('background', rgba($black, .5));
  position: fixed;
  width: 100%;
  z-index: 3;
  top: rem($navH);
  left: 0;
  opacity: 0;
  height: 0;
  z-index: -1;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.58, 1);

  &.fill--active {
    height: 100vh;
    z-index: 3;
    opacity: 1;
  }
}

.mobile-nav {
  display: block;
  width: 85%;
  min-height: calc(100vh - #{$navH + 'px'});
  position: fixed;
  margin-left: -85%;
  left: 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
  background: $white;

  &.mobile-nav--active {
    margin: 0;
    z-index: 6;
  }
  @include mq('tablet') {
    display: none;
  };

  .nav-scroll {
    height: calc(95vh - #{$navH + 'px'});
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.nav-scroll {
  position: relative;
}

.logo-holder {
  padding: rem(24);
  position: relative;
  width: 100%;
  overflow: hidden;
  @include mq('tablet') {
    display: none;
  }
}

.mobile-toggle {
  background: none;
  border: 0;
  padding: 0;
  outline: none;
  text-align: left;
  @include mq('tablet') {
    display: none;
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }
}

.hamburger-box {
  width: 40px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 40px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  top: 2px;
  transition: background-color 0s 0.13s linear;
  &:before,
  &:after{
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &:before{
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  &:after{
    bottom: -10px;
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.mobile-nav--active{
  .hamburger--spring{
    .hamburger-inner{
      transition-delay: 0.22s;
      background-color: transparent;
      &:before{
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(45deg);
      }
      &:after{
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(-45deg);
      }
    }
  }
}

.legal {
  position: absolute;
  bottom: rem(10);
  left: rem(24);
  margin: 0;
  font-family: $barlow;
  font-weight: 200;
  font-size: rem(14);
}
