.copy-holder{
  max-width: rem(968);
  margin: 0 auto;
  padding: 0 rem(24);
  margin-top: rem(30);
  @include mq('tablet'){
    margin-top: rem(60);
  }
  p{
    font-size: rem(14);
    line-height: rem(24);
    font-family: $barlow;
    font-weight: 200;
    @include mq('tablet'){
      font-size: rem(24);
      line-height: rem(36);
    }
  }
}
