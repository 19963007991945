@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon.ttf?c206w2") format("truetype"), url("/fonts/icomoon.woff?c206w2") format("woff"), url("/fonts/icomoon.svg?c206w2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  padding-top: 4.5rem; }
  body.mobile-nav--open {
    overflow: hidden; }
    body.mobile-nav--open .app {
      overflow: hidden; }

.main-content-holder {
  transition: all 0.3s cubic-bezier(0, 0, 0.58, 1); }
  .main-content-holder.mobile-nav--active {
    transform: translate3d(85%, 0, 0); }

.hero-holder {
  position: relative; }
  .hero-holder h1 {
    margin: 0;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    text-align: center;
    color: #000;
    width: 100%;
    font-size: 2.75rem;
    line-height: 3rem;
    margin-top: 3.125rem; }
    @media only screen and (min-width: 768px) {
      .hero-holder h1 {
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        color: #fff;
        font-size: 7.5rem;
        line-height: 8.25rem;
        max-width: 62.5rem; } }

.hero__img {
  position: relative;
  background: url("../images/bg.jpg") no-repeat;
  background-size: cover;
  height: 15rem; }
  @media only screen and (min-width: 768px) {
    .hero__img {
      height: 40rem; } }

.copy-holder {
  max-width: 60.5rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  margin-top: 1.875rem; }
  @media only screen and (min-width: 768px) {
    .copy-holder {
      margin-top: 3.75rem; } }
  .copy-holder p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-family: "Barlow", sans-serif;
    font-weight: 200; }
    @media only screen and (min-width: 768px) {
      .copy-holder p {
        font-size: 1.5rem;
        line-height: 2.25rem; } }

.main-nav {
  background: #91321a;
  display: flex;
  flex-flow: row;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99; }
  @media only screen and (min-width: 768px) {
    .main-nav {
      overflow: visible; } }

.main-nav__primary {
  margin: 0;
  padding: 0;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .main-nav__primary {
      overflow: visible; } }
  .main-nav__primary li {
    display: block;
    list-style: none;
    position: relative; }
    .main-nav__primary li.main-nav__has-drop:after {
      content: "";
      display: block;
      position: absolute;
      font-family: 'icomoon';
      right: 1.5rem;
      top: 1.5rem;
      font-size: 1rem;
      color: #91321a;
      transform: rotate(90deg);
      transition: transform 0.3s cubic-bezier(0, 0, 0.58, 1); }
      @media only screen and (min-width: 768px) {
        .main-nav__primary li.main-nav__has-drop:after {
          display: none; } }
    @media only screen and (min-width: 768px) {
      .main-nav__primary li {
        display: inline-block; } }
    .main-nav__primary li.main-nav__primary--active:after {
      transform: rotate(-90deg); }
    .main-nav__primary li.main-nav__primary--active > a {
      background: #fff;
      color: #91321a; }
    .main-nav__primary li.main-nav__primary--active .sub-nav {
      opacity: 1;
      max-height: 1000px; }
      @media only screen and (min-width: 768px) {
        .main-nav__primary li.main-nav__primary--active .sub-nav {
          z-index: 10; } }
    .main-nav__primary li a {
      padding: 1.5rem;
      display: block;
      text-decoration: none;
      font-family: "Fjalla One", sans-serif;
      color: #000;
      font-size: 1.3125rem;
      transition: all 0.3s cubic-bezier(0, 0, 0.58, 1); }
      @media only screen and (min-width: 768px) {
        .main-nav__primary li a {
          color: #fff; } }
      .main-nav__primary li a:hover {
        background: #fff;
        color: #91321a; }

.sub-nav {
  margin: 0;
  padding: 0;
  background: #fff;
  left: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.58, 1); }
  @media only screen and (min-width: 768px) {
    .sub-nav {
      min-width: 155%;
      position: absolute;
      z-index: -1;
      overflow: hidden; } }
  .sub-nav li {
    width: 100%; }
    .sub-nav li a {
      color: #000;
      font-family: "Barlow", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 3rem;
      padding: 0;
      display: block;
      padding: 0 1.5rem;
      white-space: nowrap;
      transition: background 0.3s cubic-bezier(0, 0, 0.58, 1); }
      .sub-nav li a:hover {
        background: #eee; }

.fill {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 4.5rem;
  left: 0;
  opacity: 0;
  height: 0;
  z-index: -1;
  transition: opacity 0.3s cubic-bezier(0, 0, 0.58, 1); }
  .fill.fill--active {
    height: 100vh;
    z-index: 3;
    opacity: 1; }

.mobile-nav {
  display: block;
  width: 85%;
  min-height: calc(100vh - 72px);
  position: fixed;
  margin-left: -85%;
  left: 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.58, 1);
  background: #fff; }
  .mobile-nav.mobile-nav--active {
    margin: 0;
    z-index: 6; }
  @media only screen and (min-width: 768px) {
    .mobile-nav {
      display: none; } }
  .mobile-nav .nav-scroll {
    height: calc(95vh - 72px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }

.nav-scroll {
  position: relative; }

.logo-holder {
  padding: 1.5rem;
  position: relative;
  width: 100%;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .logo-holder {
      display: none; } }

.mobile-toggle {
  background: none;
  border: 0;
  padding: 0;
  outline: none;
  text-align: left; }
  @media only screen and (min-width: 768px) {
    .mobile-toggle {
      display: none; } }

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 20px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 40px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger-inner:before, .hamburger-inner:after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner:before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger-inner:after {
    bottom: -10px;
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.mobile-nav--active .hamburger--spring .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent; }
  .mobile-nav--active .hamburger--spring .hamburger-inner:before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .mobile-nav--active .hamburger--spring .hamburger-inner:after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

.legal {
  position: absolute;
  bottom: 0.625rem;
  left: 1.5rem;
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-size: 0.875rem; }
