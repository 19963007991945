@function strip-unit($number) {
  @if type-of($number )== 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@function rem($pixels, $context: $browser-context) {
   @return #{strip-unit($pixels)/$context}rem;
}


@mixin mq($width, $type: min) {
   @if map_has_key($breakpoints, $width){
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
       @content;
    }
  }
}

@mixin alpha-attribute($attribute, $color) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  #{$attribute}: $color;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}
