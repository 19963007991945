.hero-holder{
  position: relative;
  h1 {
    margin: 0;
    font-family:$barlow;
    font-weight: 600;
    text-align: center;
    color: $black;
    width: 100%;
    font-size: rem(44);
    line-height: rem(48);
    margin-top: rem(50);
    @include mq('tablet'){
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      color: $white;
      font-size:rem(120px);
      line-height: rem(132px);
      max-width: rem(1000px);
    };
  }
}

.hero__img{
  position: relative;
  background: url('../images/bg.jpg') no-repeat;
  background-size: cover;
  height: rem(240px);
  @include mq('tablet'){
    height: rem(640px);
  }
}
