$browser-context: 16;

$breakpoints: (
    "phone":        300px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    'midpoint':     980px,
    "tablet-wide":  1024px,
    "desktop":      1300px,
    "desktop-wide": 1440px
);

$red: #91321a;
$lightGray: #eee;
$black: #000;
$white: #fff;
$toggleOpen:"\e900";
$toggleClose: "\e901";
$arrow: "\e902";
$navH: 72;
//fonts
$fjallaOne: 'Fjalla One', sans-serif;
$barlow: 'Barlow', sans-serif;
