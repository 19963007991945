@import "node_modules/normalize-scss/sass/normalize";
@import "vars";
@import "mixins";
@import "fonts";
@include normalize();

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  padding-top: rem($navH);
  &.mobile-nav--open{
    overflow: hidden;
    .app{
      overflow: hidden;
    }
  }
}

.main-content-holder{
  transition: all .3s cubic-bezier(0, 0, 0.58, 1);
  &.mobile-nav--active{
    transform: translate3d(85%, 0, 0);
  }
}

@import "hero";
@import "copy";
@import "nav";
